/* dice.styles.css */

.dice-section {
  padding: 80px 20px; /* Adjust padding as needed */
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #fff; /* White background */
}

.header-section {
  margin-bottom: 20px;
}

.dice-image {
  width: 100%;
  max-width: 500px; /* Adjust max-width as needed */
  margin-bottom: 20px;
  border-radius: 10px; /* Add border-radius for a rounded appearance */
}

.text-content {
  max-width: 600px; /* Adjust max-width as needed */
  margin: 0 auto;
}

/* Responsive Styles */
@media (max-width: 767px) {
  .dice-section {
    padding: 40px 20px; /* Adjust padding for smaller screens */
  }

  .dice-image {
    max-width: 300px; /* Adjust max-width for smaller screens */
  }

  .text-content {
    max-width: 100%; /* Full width on smaller screens */
  }
}
