/* MagicSoon.styles.css */

.magic-soon-section {
  background-color: #000; /* Set the background color */
  color: #fff; /* Set the text color */
  padding: 80px 20px; /* Adjust padding as needed */
  text-align: center;
}

.magic-soon-text {
  font-size: 2em; /* Adjust font size as needed */
  font-weight: bold;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.appear {
  opacity: 1;
}

/* Responsive Styles */
@media (max-width: 767px) {
  .magic-soon-section {
    padding: 40px 20px; /* Adjust padding for smaller screens */
  }

  .magic-soon-text {
    font-size: 1.5em; /* Adjust font size for smaller screens */
  }
}
