/* Header.styles.css */
.header {
  background-color: white;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  color: #000;
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: bold;
}

.nav {
  display: flex;
}

.nav-links {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.nav-links li {
  margin-left: 1rem;
}

.nav-links a {
  color: #000;
  text-decoration: none;
  font-size: 1.1rem;
  transition: color 0.3s ease;
}

.nav-links a:hover {
  color: #00ff00;
}

/* Adjustments for smaller screens (e.g., mobile devices) */
@media (max-width: 767px) {
  .header {
    flex-direction: column; /* Stack items vertically on smaller screens */
  }

  .nav {
    margin-top: 1rem; /* Add space between logo and navigation on smaller screens */
  }

  .nav-links {
    flex-direction: column; /* Stack navigation links vertically on smaller screens */
    text-align: center; /* Center text content */
  }

  .nav-links li {
    margin-left: 0; /* Remove left margin for smaller screens */
    margin-bottom: 0.5rem; /* Add margin between navigation links on smaller screens */
  }
}
