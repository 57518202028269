/* Hero.styles.css */

.hero {
  position: relative;
  background: #fff;
  color: #000;
  padding: 60px 0;
  text-align: center;
}

.hero-content {
  display: flex;
  max-width: 900px;
  margin: 0 auto;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 3.8s ease, transform 3.8s ease;
}

.hero-content.animate {
  opacity: 1;
  transform: translateY(0);
}

.nft-card {
  width: 300px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.9);
  margin-right: 20px;
  transition: transform 0.3s ease;
}

.nft-card:hover {
  transform: scale(1.1) rotate(15deg);
}

.text-content {
  flex: 1;
  text-align: left;
}

h1 {
  font-size: 3.5em;
  margin-bottom: 20px;
}

p {
  font-size: 2.2em;
  margin-bottom: 30px;
  color: #666;
}

.cta-button {
  background: #2ecc71;
  color: #fff;
  font-size: 1.2em;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background: #27ae60;
}

/* Adjustments for smaller screens (e.g., iPhones) */
@media (max-width: 767px) {
  .hero {
    padding: 40px 0;
  }

  .hero-content {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .text-content {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .nft-card {
    width: 100%;
    max-width: 300px;
    margin-right: 0;
    margin-bottom: 20px;
  }

  h1 {
    font-size: 2.5em;
  }

  p {
    font-size: 1.8em;
    margin-bottom: 20px;
  }

  .cta-button {
    font-size: 1em;
  }
}
