/* IRLUtility.styles.css */

.irl-utility-section {
  padding: 80px 20px; /* Adjust padding as needed */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: white;
}

.image-container {
  max-width: 600px; /* Adjust max-width as needed */
  margin-right: 20px; /* Add margin between image and text */
}

.utility-image {
  width: 100%;
  max-width: 100%; /* Full width within container */
  border-radius: 10px; /* Add border-radius for a rounded appearance */
}

.text-container {
  max-width: 600px; /* Adjust max-width as needed */
}

/* Responsive Styles */
@media (max-width: 767px) {
  .irl-utility-section {
    flex-direction: column; /* Stack items vertically on smaller screens */
    padding: 40px 20px; /* Adjust padding for smaller screens */
  }

  .image-container {
    max-width: 100%; /* Full width on smaller screens */
    margin-right: 0; /* Remove right margin for smaller screens */
    margin-bottom: 20px; /* Add margin below image on smaller screens */
  }

  .utility-image {
    border-radius: 0; /* Remove border-radius on smaller screens */
  }

  .text-container {
    max-width: 100%; /* Full width on smaller screens */
  }
}
